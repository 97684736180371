module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-KSMJ7D8","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"function () {\n          return {\n            event: \"optimize.activate\",\n            originalLocation: document.location.protocol + '//' +\n            document.location.hostname +\n            document.location.pathname +\n            document.location.search\n          }\n        }"},"enableWebVitalsTracking":false,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
